.home-category-links {
	width: 100%;
	text-align: right;
}

.home-category-link > * {
	font-size: 24px;
	text-decoration: none;
}

@media only screen and (max-width: 800px) {
	
	.home-category-links {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		text-align: left;
	}
	
	.home-categories-header  {
		text-align: left;
	}
	
	.home-category-link {
		padding-right: 10px;
		padding-top: 10px;
	}

	.home-categories-header {
		font-size: 16px;
	}
	
	.home-category-link > * {
		font-size: 15px;
	}
	
}