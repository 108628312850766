.content {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	padding: 0;
}

.page {
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	padding: 20px;
}

.layout {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	max-width: 1600px;
	margin-left: auto;
	margin-right: auto;
}

.buttons .MuiButton-root {
	max-width: 250px; /* beyond this width buttons don't look good */
	min-width: 150px;
	margin-right: 20px;
	margin-bottom: 20px;
}

.MuiTextField-root {
	width: 100%;
}

#stripe-inputs {
	padding-top: 20px;
	padding-bottom: 5px;
	margin-bottom: 15px;
	border-bottom: solid #bbbbbb 1px;
}

.invisible {
	display: none;
}

@media only screen and (max-width: 600px) {

	td.MuiTablePagination-root .MuiToolbar-gutters {
		padding-left: 0;
	}
	
	td.MuiTablePagination-root .MuiTablePagination-input {
		display: inline-block;
		width: auto;
	}
	
	td.MuiTablePagination-root .MuiTablePagination-input .MuiSelect-root {
		width: auto;
		margin-right: 5px;
		margin-left: 0;
		padding-right: 0;
	}
	
	td.MuiTablePagination-root .MuiTablePagination-input .MuiSelect-root .MuiSelect-select {
		padding-left: 0;
		padding-right: 15px;
		width: 30px;
	}
	
	td.MuiTablePagination-root .MuiTablePagination-actions {
		margin-left: 0;
	}
	
	td.MuiTablePagination-root .MuiTablePagination-actions .MuiIconButton-root {
		padding: 3px;
	}
}

@media only screen and (max-width: 750px) {
	.page {
		padding: 10px;
	}
}