.featured-product-card {
	flex-basis: 19%;
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: 0;
	margin-bottom: 20px;
}

.featured-product {
	display: flex;
	flex-direction: column;
}

.featured-product-image {
	width: 100%;
	padding: 0;
	text-align: center;
}

.featured-product-image > img {
	border: solid black 1px;
}

.featured-product-header {
	font-size: 1.2em;
	font-weight: bold;
	text-align: center;
}

.featured-product-text {
	width: 100%;
	height: 60px;
	text-align: center;
	word-wrap: break-spaces;
	overflow: hidden;
	text-overflow: ellipsis;
}

.featured-product-price {
	width: 100%;
	text-align: center;
	font-size: 1.2em;
}

.featured-product-link {
	width: 100%;
	text-align: center;
	padding-top: 10px;
}

@media only screen and (max-width: 700px) {
	.featured-product-card {
		flex-basis: 32%;
	}
}

@media only screen and (max-width: 500px) {
	.featured-product-card {
		flex-basis: 48%;
	}
}

