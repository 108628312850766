.nav .menu {
	width: 100%;
	height: 60px;
	display: flex;
	flex-direction: row;
}

.nav .menu .links {
	height: 60px;
	padding: 0;
	margin: 0;
	flex-grow: 1;
}

.nav .menu .links > * {
	margin-top: 11px;
	font-size: 16px;
}

.nav .menu .cart {
	margin-top: 9px;
	margin-right: 10px;
}

.nav .menu .cart .cart-badge {
	position: relative;
	top: -20px;
}

.nav .menu .cart .cartIcon {
	color: white;
}

@media only screen and (max-width: 600px) {
	.nav .menu .links > * {
		margin-top: 14px;
		font-size: 13px;
	}
}

@media only screen and (max-width: 380px) {
	.nav .menu .links > * {
		margin-top: 16px;
		font-size: 11px;
	}
	.nav .menu .cart {
		margin-right: 0;
	}
}