.content > .MuiToolbar-gutters {
	padding-left: 0;
}

.header {
	width: 100%;
	font-size: 18px;
	font-weight: bold;
	background-color: white;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.header .logo {
	padding: 0;
	height: 100px;
	width: 570px;
	display: inline-block;
}

.header .search {
	display: inline-block;
	vertical-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	margin-left: 20px;
	margin-right: 20px;
	flex-grow: 1;
}

.header .account {
	display: inline-block;
	vertical-align: top;
	margin-right: 20px;
	margin-top: 20px;
}

.header .account .MuiAvatar-colorDefault {
	background-color: #3f51b5;
	cursor: pointer;
}

.header .account .login-register > * {
	margin-bottom: 10px;
	width: 100%;
}

.logout-myaccount-menu {
	display: flex;
	flex-direction: column;
	background-color: white;
	padding: 20px;
}

@media only screen and (max-width: 600px) {
	.header {
		height: 125px;
	}
	.header .logo {
		height: 60px;
	}
	.header .logo img {
		height: 60px;
		width: 342px;
		position: relative;
		left: -20px;
		top: 10px;
	}
	.header .search {
		margin-top: 5px;
		margin-bottom: 0;
		width: 202px;
	}
	.header .account {
		position: relative;
		top: -40px;
		margin-right: 0;
		margin-top: 0;
	}
}

@media only screen and (max-width: 390px) {
	.header {
		height: 100px;
	}
	.header .logo {
		height: 40px;
		width: 228px;
		position: relative;
		left: 20px;
		top: 0;
	}
	.header .logo img {
		height: 40px;
		width: 228px;
	}
	.header .search {
		margin-top: 5px;
		margin-bottom: 0;
		margin-left: 5px;
		margin-right: 5px;
		width: 168px;
		padding: 0;
	}
	.header .account {
		position: relative;
		top: -30px;
		margin-right: 0;
		margin-top: 0;
	}
}