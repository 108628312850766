.home-slideshow-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.home-slideshow-container .home-slideshow {
	flex-basis: 75%;
	flex-grow: 3;
	flex-shrink: 3;
	max-width: 1200px;
	border: solid black 1px;
	cursor: pointer;
}

.home-slideshow-container .home-categories {
	flex-basis: 25%;
	flex-grow: 1;
	flex-shrink: 1;
	max-width: 400px;
	text-align: right;
}

.home-featured-products {
	width: 100%;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}

@media only screen and (max-width: 800px) {
	
	.MuiCardContent-root.home-slideshow-container {
		flex-wrap: wrap;
		padding: 10px;
	}
	
	.home-slideshow-container .home-slideshow, .home-slideshow-container .home-categories {
		flex-basis: 100%;
		flex-grow: 1;
		flex-shrink: 1;
		max-width: none;
	}
}